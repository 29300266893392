import splash from "./splash.webp";
import "./App.css";
// import "https://kit.fontawesome.com/a076d05399.js";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaLinkedinIn, FaGithub, FaRegUser } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={splash} className="splash" alt="Seth in the mountains." />
        <div className="content">
          <h2>Seth Wright</h2>
          <p>
            Software Engineer. Tokyo-based. Tech, science, and news junkie. Code
            Chrysalis Immersive Bootcamp graduate. 日本語で仕事した経験もある。
            <br />
            <br />
            Building apps in JS, Typescript, React, Vue, Dart/Flutter; servers
            with Golang, Node.JS, Graph QL, Ract, Python, PostgreSQL; deploying
            on Heroku, Netlify, Google Firebase. Collaborating with Github,
            GitLab.
            <br />
            <br />
            <div>
              <div className="links">
                <a href="https://www.linkedin.com/in/wseth/">
                  <FaLinkedinIn />
                  <i className="icon fab fa-linkedin-in"></i>
                </a>
              </div>
              <div className="links">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div className="links">
                <a href="http://github.com/sethwright">
                  <FaGithub />
                  <i className="icon fab fa-github"></i>
                </a>
              </div>
              <div className="links">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div className="links">
                <a
                  href="https://www.wantedly.com/id/sethwright"
                  className="icon"
                >
                  <FaRegUser />
                  {/* Wantedly */}
                </a>
              </div>
            </div>
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;

// f05d5e e7ecef 272932
